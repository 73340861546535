import React from 'react';

const videoOptions = {
    loop: true,
    autoSetup: false,
    preload: 'metadata',
    autoplay: true,
    loadingSpinner: true,
    html5: {
        nativeControlsForTouch: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
            limitRenditionByPlayerDimensions: false,
            smoothQualityChange: true,
            bandwidth: 6194304,
            overrideNative: true
        }
    }
};

export default class FlippableVideo extends React.Component {

    constructor(props) {
        super(props);

        this.obverseSideVideoEl = React.createRef();
        this.reverseSideVideoEl = React.createRef();
    }

    synchronizeVideos() {
        const timeDiff = Math.abs(this.obverseSideVideo.currentTime() - this.reverseSideVideo.currentTime());
    
        if (this.obverseSideVideo.el().classList.contains('js-video-hidden')) {
            if (timeDiff > 0.5) {
                this.reverseSideVideo.currentTime(this.obverseSideVideo.currentTime());
            }
        } else {
            if (timeDiff > 0.5) {
                this.obverseSideVideo.currentTime(this.reverseSideVideo.currentTime());
            }
        }
    };

    flipVideos() {
        this.obverseSideVideo.el().classList.toggle('js-video-hidden');
        this.reverseSideVideo.el().classList.toggle('js-video-hidden');
        this.synchronizeVideos();
    }

    componentDidMount() {
    this.obverseSideVideo = window.videojs(this.obverseSideVideoEl.current, videoOptions);
    this.reverseSideVideo = window.videojs(this.reverseSideVideoEl.current, videoOptions);

    this.obverseSideVideo.src({ src: this.props.obverseVideoSrc, type: 'video/webm' });
    this.reverseSideVideo.src({ src: this.props.reverseVideoSrc, type: 'video/webm' });

    this.obverseSideVideoEl.current.addEventListener("mouseenter", e => {
        this.synchronizeVideos();
        this.obverseSideVideo.pause();
        this.reverseSideVideo.pause();
    });

    this.obverseSideVideoEl.current.addEventListener("mouseleave", e => {
        this.synchronizeVideos();
        this.obverseSideVideo.play();
        this.reverseSideVideo.play();
    });

    this.reverseSideVideoEl.current.addEventListener("mouseenter", e => {
        this.synchronizeVideos();
        this.obverseSideVideo.pause();
        this.reverseSideVideo.pause();
    });

    this.reverseSideVideoEl.current.addEventListener("mouseleave", e => {
        this.synchronizeVideos();
            this.obverseSideVideo.play();
            this.reverseSideVideo.play();
    });

   this.obverseSideVideo.on('click', evt => {
        if (evt.target.tagName === 'VIDEO') {
            this.flipVideos();
        }
    });

    this.reverseSideVideo.on('click', evt => { 
        if (evt.target.tagName === 'VIDEO') {
            this.flipVideos();
        }
    });

    this.obverseSideVideo.on('touchstart', evt => { 
        if (evt.target.tagName === 'VIDEO') {
            this.flipVideos();
        }
    });

    this.reverseSideVideo.on('touchstart', evt => { 
        if (evt.target.tagName === 'VIDEO') {
            this.flipVideos();
        }
    });

    /* TODO: consider re-enabling this
   this.obverseSideVideo.on('timeupdate', evt => { 
        this.synchronizeVideos();
    });

    this.reverseSideVideo.on('timeupdate', evt => { 
        this.synchronizeVideos();
    });
    */
    }

   render() {
       return (
           <div>
                <div className="row player-row-container">
                    <div id="player-container">
                    <video ref={this.obverseSideVideoEl} className="video-js unselectable" playsInline={true} muted="muted" unselectable="on" disableRemotePlayback></video>
                    <video ref={this.reverseSideVideoEl} className="video-js unselectable js-video-hidden" playsInline={true} muted="muted" unselectable="on" disableRemotePlayback></video>
                    </div>
                </div>
                <div className="row tap-to-rotate">
                    <span className="unselectable" unselectable="on" onClick={() => this.flipVideos()}>Tap to flip</span>
                </div>
        </div>
        );
   }
};
