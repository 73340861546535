import React from 'react';

export default class Footer extends React.Component {
  render() {
    return (
        <div className="site-footer">
            <div className="row">
                <div className="col-md-6">
                    <p className="copyright-text">Copyright &copy; 2021 CoinArtworks</p>
                </div>
                <div className="col-md-6">
                    <div className="social-icons-footer">
                        <ul>
                            <li>Follow us on:</li>
                            <li><a target="_blank" rel="noreferrer" href="https://twitter.com/coinartworks"><i className="fa fa-twitter"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/coinartworks"><i className="fa fa-instagram"></i></a></li>
                            <li><a href="mailto:contact@coinartworks.xyz"><i className="fa fa-envelope"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
  }
};
