export const artworksCards = [
  {
    tokenId: '1',
    artworkId: '2thgraz',
    title: "Double Thaler",
    subtitle: "Leopold I, Double Thaler 1682, Graz",
    description: "Beautiful double thaler minted in 1682 under the supervision of the mint master Johann Anton Nowak in the Austro-Hungarian city of Graz."
  },
  {
    tokenId: '6',
    artworkId: 'medtur',
    title: "SILVER MEDAL",
    subtitle: "Leopold I, Silver medal 1684, Alliance against the Turks",
    description: "Minted in 1684, this medal was to honor the victory of the Holy League over the Ottoman Empire during the Great Turkish War of the 17th century."
  },
  {
    tokenId: null,
    artworkId: 'smelbing',
    title: "SILVER MEDAL",
    subtitle: "Peter I, Silver medal 1555, Capture of Elbing",
    description: "Truly unique medal minted to commemorate the capture of Elbing in 1555."
  },
  {
    tokenId: null,
    artworkId: 'smolomouc',
    title: "SILVER MEDAL",
    subtitle: "Wolfgang Hannibal Schrattenbac, Olomouc 1731",
    description: "This exceptional medal presents a relic of tongue of Saint John of Nepomuk kept in the Czech city of Olomouc."
  }
];
