import React from 'react';

const videoOptions = {
    loop: true,
    autoSetup: false,
    preload: 'metadata',
    autoplay: true,
    loadingSpinner: true,
    html5: {
        nativeControlsForTouch: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
            limitRenditionByPlayerDimensions: false,
            smoothQualityChange: true,
            bandwidth: 6194304,
            overrideNative: true
        }
    }
};

export default class SingleVideo extends React.Component {

    constructor(props) {
        super(props);

        this.videoEl = React.createRef();
        this.state = {
            paused: false
        };
    }

    togglePause() {
        if (this.state.paused) {
            this.video.play();
        } else {
            this.video.pause();
        }
        this.setState({paused: !this.state.paused});
    }

    componentDidMount() {
    this.video = window.videojs(this.videoEl.current, videoOptions);

    this.video.src({ src: this.props.videoSrc, type: 'video/webm' });

    this.videoEl.current.addEventListener("mouseenter", e => {
        this.video.pause();
    });

    this.videoEl.current.addEventListener("mouseleave", e => {
        if (!this.state.paused) {
            this.video.play();
        }
    });

    this.videoEl.current.addEventListener("mouseenter", e => {
        this.video.pause();
    });

   this.video.on('click', evt => {
        if (evt.target.tagName === 'VIDEO') {
            this.togglePause();
        }
    });

    this.video.on('touchstart', evt => { 
        if (evt.target.tagName === 'VIDEO') {
            this.togglePause();
        }
    });

    }

   render() {
       return (
           <div>
                <div className="row player-row-container">
                    <div id="player-container">
                    <video ref={this.videoEl} className="video-js unselectable" playsInline={true} muted="muted" unselectable="on" disableRemotePlayback></video>
                    </div>
                </div>
                <div className="row tap-to-rotate">
                    <span className="unselectable" unselectable="on" onClick={() => this.togglePause()}>{this.state.paused ? "Play" : "Pause"}</span>
                </div>
        </div>
        );
   }
};
