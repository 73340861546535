import React from 'react';

export default class ItemCard extends React.Component {

  render() {
    return (
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="team-member">
          <div className="member-thumb">
            { this.renderImg() }
          </div>
          <div className="member-infos">
            { this.renderTitle() }
            <span className="member-role">{this.props.subtitle}</span>
            <p className="member-desc">{this.props.description}</p>
            { this.renderAvailableSoon() }
          </div>
        </div>
      </div>
    );
   }

   renderImg() {
       if (this.props.tokenId) {
            return (<a href={"/token/" + this.props.tokenId}><img src={"/artworks/" + this.props.artworkId + "/img/banner.jpg"} alt="" /></a>);
        } else {
            return (<img src={"/artworks/" + this.props.artworkId + "/img/banner.jpg"} alt="" />);
        }
   }

   renderTitle() {
       if (this.props.tokenId) {
        return (<h4 className="member-name"><a href={"/token/" + this.props.tokenId}>{this.props.title}</a></h4>);
       } else {
        return (<h4 className="member-name">{this.props.title}</h4>);
       }
   }

   renderAvailableSoon() {
       if (this.props.tokenId) {
        return (<p className="view-artwork"><a href={"/token/" + this.props.tokenId}>VIEW ARTWORK</a></p>);
       } else {
        return (<p className="available-soon">AVAILABLE SOON</p>);
       }
   }
};
