import React from 'react';
import Home from './Home';
import Item from './Item';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route
              path="/token/:tokenId"
              render={({ match }) => <Item tokenId={match.params['tokenId']} />} />
          </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;
