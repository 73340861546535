import React from 'react';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';
import ItemCard from './ItemCard';
import NewArtworksComingWeeklyCard from './NewArtworksComingWeeklyCard';
import { artworksCards } from './cards';

export default class App extends React.Component {

  componentDidMount() {
   window.initTooltip();
  }

  render() {
    return (
      <div className="container">
              <div className="row">
                  <div className="col-md-12">
                    <HeaderLogo />
  
                      <div id="menu-container">
                          <div id="menu-2" className="content about-us">
                              <div className="content-inner">
                                  <div className="row description-row">
                                      <div className="col-md-1"></div>
                                      <div className="col-md-6">
                                          <h3 className="widget-title">Unique numismatics revived in the 21st century</h3>
                                          <p>
                                              CoinArtworks was founded on a desire to bridge the fascinating world of physical masterpieces with, equally fascinating, world of digital assets.
                                            </p>
                                            <p>
                                              Our artworks are an attempt to capture the ingenuity and incredible craftsmanship of the past masters and make them available for everyone as non-fungible tokens.
                                          </p>
                                          <p>
                                              Exclusive recordings are created by passionate content creators under the eye of piece's owner. Each one is represented by a limited number of NFT tokens, guaranteed never to increase.
                                          </p>
                                      </div>
                                      <div className="col-md-5">
                                          <h3 className="widget-title">Catalog stats</h3>
                                          <table className="nft-info-table">
                                            <tbody>
                                              <tr>
                                                  <td><span className="table_key"><i className="fa fa-cogs"></i>NFT platform:</span></td>
                                                  <td><a target="_blank" rel="noreferrer" href="https://polygon.technology/">Polygon</a></td>
                                              </tr>
                                              <tr>
                                                  <td><span className="table_key"><i className="fa fa-file-contract"></i>Token contract:</span></td>
                                                  <td><a target="_blank" rel="noreferrer" href="https://polygonscan.com/token/0xa777162d6c8a0a5f5660163070df7104624f13ca">0xa77716..ca <i className="fa fa-external-link-alt"></i></a></td>
                                              </tr>
                                              <tr>
                                                  <td><span className="table_key"><i className="fa fa-plus-circle"></i>NFTs minted:</span></td>
                                                  <td>7</td>
                                              </tr>
                                              <tr data-toggle="tooltip" data-placement="bottom" title="We will never mint more than 10 000 tokens. This limit is enforced by our Smart Contract code.">
                                                  <td><span className="table_key"><i className="fa fa-chart-line"></i>Max supply:</span></td>
                                                  <td>10 000 NFTs <i className="fa fa-info-circle"></i></td>
                                              </tr>
                                              </tbody>
                                          </table>
                                          <br />
                                          <h3 className="widget-title">Our artworks are avilable at</h3>
                                          <p className="available-at">
                                              <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/coinartworks">OpenSea <i className="fa fa-external-link-alt"></i></a>
                                          </p>
                                      </div>
                                  </div>
                                  <div className="our-team">
                                      <div className="row team-row">
                                          <div className="col-md-12 col-browse">
                                              <h3 className="widget-title">browse artworks</h3>
                                          </div>
                                          {artworksCards.map((card, idx) =>
                                          <ItemCard key={idx} tokenId={card.tokenId} artworkId={card.artworkId} title={card.title} subtitle={card.subtitle} description={card.description} />
                                          )}
                                          <NewArtworksComingWeeklyCard />
                                      </div>
                                      <div className="row">
                                          <div className="col-md-12 text-center">
                                      <ul className="pagination pagination-sm">
                                        <li className="disabled">
                                        <a href="/" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                        </li>
                                        <li className="disabled"><a href="/">1</a></li>
                                        <li  className="disabled">
                                        <a href="/" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                                    </div>
                                  </div>
                              </div>
                         <Footer />

                          </div>
                          
                </div>
            </div>
          </div>
      </div>
    );
   }
};