import React from 'react';

export default class HeaderLogo extends React.Component {
  render() {
    return (
      <div className="header-logo">
        <a href="/">
          <img src="/images/logo.png" alt="CoinArtworks logo" />
        </a>
      </div>
    );
  }
};
