import React from 'react';
import axios from 'axios';
import HeaderLogo from './HeaderLogo';
import Footer from './Footer';
import ItemCard from './ItemCard';
import FlippableVideo from './FlippableVideo';
import SingleVideo from './SingleVideo';
import NewArtworksComingWeeklyCard from './NewArtworksComingWeeklyCard';
import { tokenById } from './tokens';
import { artworksCards } from './cards';

export default class Item extends React.Component {

    constructor(props) {
        super(props);

        this.tokenInfo = tokenById(this.props.tokenId);

        this.state = {
            metadata: null
        };
    }


    componentDidMount() {
        this.loadMetadata();
    }

    loadMetadata() {
        axios.get('/token-metadata/' + this.props.tokenId)
            .then(response => this.setState({metadata: response.data}));
    }

    componentDidUpdate() {
        window.initTooltip();
    }

  render() {
    if (this.state.metadata == null) {
        return this.renderLoading();
    } else {
        return this.renderTokenContent();
    }
   }

   renderLoading() {
    return this.baseTemplate(
        "Loading token \"" + this.props.tokenId + "\"...",
        (
            <div className="text-center">
                <img className="loading-gif" src="/images/loading.gif" alt="Loading..." />
            </div>
        )
    );
   }

   getMetadataAttribute(traitType) {
       const maybeAttributeEl = this.state.metadata.attributes.find(el => el['trait_type'] === traitType);
       if (maybeAttributeEl) {
           return maybeAttributeEl.value;
       } else {
           return "";
       }
   }

   renderTokenContent() {
       const diameterAttribute = this.getMetadataAttribute("Diameter");
       const weightAttribute = this.getMetadataAttribute("Weight");
       const metalAttribute = this.getMetadataAttribute("Metal");

       return this.baseTemplate(
           this.state.metadata.name,
           (
           <div>
            {this.renderVideo()}  
                       <div className="row description-row">
                           <div className="col-md-1"></div>
                           <div className="col-md-6">
                               <h3 className="widget-title">{this.state.metadata.name}</h3>
                               <p className="display-linebreak">{this.state.metadata.description}</p>
                           </div>
                           <div className="col-md-5">
                               <h3 className="widget-title">Item details</h3>
                               <table className="nft-info-table">
                                   <tbody>
                                   <tr>
                                       <td><span className="table_key"><i className="far fa-circle"></i>Diameter:</span></td>
                                       <td>{diameterAttribute}</td>
                                   </tr>
                                   <tr>
                                       <td><span className="table_key"><i className="fa fa-weight"></i>Weight:</span></td>
                                       <td>{weightAttribute}</td>
                                   </tr>
                                   <tr>
                                       <td><span className="table_key"><i className="fa fa-asterisk"></i>Metal:</span></td>
                                       <td>{metalAttribute}</td>
                                   </tr>
                                   </tbody>
                               </table>
                               <br />
                               <h3 className="widget-title">Digital artwork</h3>
                               <table className="nft-info-table">
                                   <tbody>
                                   <tr>
                                       <td><span className="table_key"><i className="far fa-calendar-check"></i>Created:</span></td>
                                       <td>{this.tokenInfo.createdAt}</td>
                                   </tr>
                                   <tr>
                                       <td><span className="table_key"><i className="fa fa-hashtag"></i>NFT:</span></td>
                                       <td><span data-toggle="tooltip" data-placement="bottom" title={"Guaranteed that no more than " + this.tokenInfo.tokensCount + " NFTs of this artwork will ever exist."}>#{this.tokenInfo.tokenNumber} out of {this.tokenInfo.tokensCount} <i className="fa fa-info-circle"></i></span><a target="_blank" rel="noreferrer" href={"https://polygonscan.com/token/0xa777162d6c8a0a5f5660163070df7104624f13ca?a=" + this.props.tokenId}><i className="fa fa-external-link-alt"></i></a></td>
                                   </tr>
                                   <tr>
                                       <td><span className="table_key"><i className="fa fa-user"></i>Owner:</span></td>
                                       <td><a target="_blank" rel="noreferrer" href="https://opensea.io/collection/coinartworks">On sale <i className="fa fa-external-link-alt"></i></a></td>
                                   </tr>
                                   <tr>
                                       <td colSpan="2" className="owner-claim">Are you the owner? <a href="mailto:contact@coinartworks.xyz?subject=Artwork%20claim">Claim your high-res copy.</a></td>
                                   </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                </div>
            )
        );
   }

   renderVideo() {
       if (this.tokenInfo.artworkType === 'flippable-video') {
        return (<FlippableVideo
                obverseVideoSrc={"/artworks/" + this.tokenInfo.artworkId + "/video/obverse.webm"}
                reverseVideoSrc={"/artworks/" + this.tokenInfo.artworkId + "/video/reverse.webm"} />);
       } else if (this.tokenInfo.artworkType === 'single-video') {
        return (<SingleVideo
            videoSrc={"/artworks/" + this.tokenInfo.artworkId + "/video/preview.webm"} />);
       }
   }

   baseTemplate(title, content) {
       return (
       <div className="container">
        <div className="row">
            <div className="col-md-12">
                <HeaderLogo />
                <div id="menu-container">
                <div id="menu-2" className="content about-us">
                    <div className="page-header">
                        <a href="/"><span><i className="fa fa-long-arrow-left"></i> Back to catalog</span></a>
                        <br /><br />
                        <h2 className="page-title">{title}</h2>
                    </div>
                    <div className="content-inner">
                        {content}
                        <div className="our-team">
                            <div className="row team-row">
                                <div className="col-md-12 col-browse">
                                    <h3 className="widget-title">more artworks</h3>
                                </div>
                            {artworksCards.filter(card => card.artworkId != this.tokenInfo.artworkId).map((card, idx) =>
                                <ItemCard key={idx} tokenId={card.tokenId} artworkId={card.artworkId} title={card.title} subtitle={card.subtitle} description={card.description} />
                            )}
                            <NewArtworksComingWeeklyCard />
                            </div>
                        </div>
                    </div>
                    <Footer />

                </div>
                    
                </div>
            </div>
            </div>
        </div>
     );
   }
};
