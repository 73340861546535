import React from 'react';

export default class NewArtworksComingWeeklyCard extends React.Component {

  render() {
    return (
        <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="team-member new-artworks-coming">
                <div className="member-thumb">
                    <img src="/images/bg.jpg" alt="" />
                </div>
                <div className="member-infos">
                    <h4 className="member-name">NEW ARTWORKS<br />COMING WEEKLY</h4>
                    <p className="member-role"><span className="subscribe" sp-show-form="203824">Subscribe to get notified</span></p>
                </div>
            </div>
        </div>
    );
   }
};
