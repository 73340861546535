export const tokenById = (id) => {

  const tokens = {
    '1': { artworkId: '2thgraz', tokenNumber: '1', tokensCount: '5', createdAt: '1 Nov 2021', artworkType: 'flippable-video' },
    '2': { artworkId: '2thgraz', tokenNumber: '2', tokensCount: '5', createdAt: '1 Nov 2021', artworkType: 'flippable-video' },
    '3': { artworkId: '2thgraz', tokenNumber: '3', tokensCount: '5', createdAt: '1 Nov 2021', artworkType: 'flippable-video' },
    '4': { artworkId: '2thgraz', tokenNumber: '4', tokensCount: '5', createdAt: '1 Nov 2021', artworkType: 'flippable-video' },
    '5': { artworkId: '2thgraz', tokenNumber: '5', tokensCount: '5', createdAt: '1 Nov 2021', artworkType: 'flippable-video' },
    '6': { artworkId: 'medtur', tokenNumber: '1', tokensCount: '2', createdAt: '16 Nov 2021', artworkType: 'single-video' },
    '7': { artworkId: 'medtur', tokenNumber: '2', tokensCount: '2', createdAt: '16 Nov 2021', artworkType: 'single-video' }
  };

  return tokens[id];
};

export const tokenContractAddress = "0xA777162D6c8a0a5f5660163070Df7104624f13Ca";
